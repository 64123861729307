// Variables
$space-none: 0;
$space-small: 15px;
$space-medium: 30px;
$space-large: 60px;

// Color variables
$benedict-black: #212121;
$benedict-white: #fff;
$benedict-white-second: #f1f1f1;
$benedict-gray: #e7e7e7;
$benedict-gray-dark: #484848;
$benedict-gray-dark-second: #445061;
$benedict-gray-dark-circle: #999B96; // Power free system svg color


$benedict-blue: #1B4D7C;
$benedict-dark-blue: darken($benedict-blue, 5%);
$benedict-light-blue: lighten($benedict-blue, 20%);
$benedict-dark-blue-footer: #1a2640;
// $benedict-light-blue: #399bde;

$benedict-red: #ff3854;
$benedict-red-second: #ff3854;

$link-color: #ff3854 !important;
$benedict-red-darker: #ff3854;
$benedict-dark-red: darken($benedict-red, 5%);
$benedict-light-red: #ff3854;
// $benedict-dark-red: #c51229;
// $benedict-light-red: #FFB3B3;

$benedict-green: #1aba8f;
// $benedict-dark-green: darken($benedict-green, 5%);
$benedict-light-green: lighten($benedict-green, 20%);
// $benedict-light-green: #8FDEC9;
$benedict-dark-green: #00956c; // Added fixed because of overwrite.css

$benedict-orange: #E5AC3B;
$color-smiley-badge: #FFC107;
$benedict-dark-orange: darken($benedict-orange, 5%);
$benedict-light-orange: lighten($benedict-orange, 20%);
// $benedict-light-orange: #FFCE5E;
// $benedict-dark-orange: #CF9B34;

$benedict-purple: #b500cf;
$benedict-dark-purple: darken($benedict-purple, 5%);
$benedict-light-purple: lighten($benedict-purple, 20%);
// $benedict-light-purple: #E1AED9;
// $benedict-dark-purple: #A14DB6;

$benedict-turquoise: #11BBC4;
$benedict-dark-turquoise: darken($benedict-turquoise, 5%);
$benedict-light-turquoise: lighten($benedict-turquoise, 20%);
// $benedict-light-turquoise: #B3E0E1;
// $benedict-dark-turquoise: #88D0D2;


// Here we will define new colors
// The new color palette won't be as bright as the previous one
// We will prefix them with benedict-new
$benedict-new-blue: #1B4D7C;
$benedict-gray-text: #C8C8C8;
$question-answered-color: #e0e7ff;
$question-not-answered-color: #fde6e8;

$einen-blick-close-icon: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 47.1 %2845422%29 - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3Eic-close%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='square'%3E%3Cg id='Benedict-Kurse-pop-up---develop-v' transform='translate%28-1396.000000, -492.000000%29' stroke='%23FFFFFF' stroke-width='3'%3E%3Cg id='pop-up' transform='translate%28-2.000000, 0.000000%29'%3E%3Cg id='ic-close' transform='translate%281400.000000, 494.000000%29'%3E%3Cpath d='M0.5,0.5 L19.0607112,19.0607112' id='Line-2'%3E%3C/path%3E%3Cpath d='M0.5,0.5 L19.0607112,19.0607112' id='Line-2' transform='translate%2810.000000, 10.000000%29 scale%28-1, 1%29 translate%28-10.000000, -10.000000%29 '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

$einen-blick-arrow-down: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='15px' height='10px' viewBox='0 0 15 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 47.1 %2845422%29 - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3ERectangle 3%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Benedict-Kurse-icons-1' transform='translate%28-871.000000, -490.000000%29' stroke-width='2' stroke='%23FFFFFF'%3E%3Cg id='1' transform='translate%28601.000000, 470.000000%29'%3E%3Cpolyline id='Rectangle-3' transform='translate%28277.500000, 21.500000%29 rotate%28-315.000000%29 translate%28-277.500000, -21.500000%29 ' points='282 17 282 26 273 26'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

$benedict-background-grey: #F8F8F8;
$benedict-copy-grey: #777;




$font-family-regular: 'EuclidCircularB-Regular-WebXL', sans-serif, Arial, sans-serif !default;
$font-family-bold: 'EuclidCircularB-Bold-WebXL', sans-serif, Arial, sans-serif !default;
$font-family-semibold: 'EuclidCircularB-Semibold-WebXL', sans-serif, Arial, sans-serif !default;
$font-family-admin: 'EuclidCircularB-Regular-WebXL', sans-serif, Arial, sans-serif !default;


$bene-dark-blue: #1A2640;
$bene-light-green: #ecfcf8;
$bene-beige: #EFEDE6;
$bene-gold-background: #E6DCBF;
$bene-gold-copy: #D9C899;
$bene-red: #ff3854;

$header-animation-speed: .15s ease-in;
$header-promo-height: 35px;
$header-promo-height-mobile: 50px;