$container-padding: 30px 70px;
$container-padding-mobile: 20px 30px;
$wrap-margin: 25px;
$wrap-margin-small: 15px;

$formfield-wrapper-padding: 30px;

$font-size: 16px;
$font-color: #4D4D4D;
$font-form-blue: #4565D4;

$half-width: 46.4%; // Calculated half of 50% - default gap

// Override Bootstrap shizzle
input[type="radio"], input[type="checkbox"] {
  margin: 4px 10px 0 0 !important;
}

// Black background fullscreen
.download-container-popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
}

.download-close-icon {
  position: absolute;
  top: 9px;
  right: 10px;
  padding: 20px;
  width: 24px;
  height: 24px;
  display: inline-block;
  background: $einen-blick-close-icon no-repeat center center;
  background-size: 14px;
  cursor: pointer;
}

.download-header {
  margin-top: 0;
  padding: $container-padding-mobile;
  @media screen and (min-width: 991px) {
    padding: $container-padding;
  }
  background: $benedict-blue;
  font-size: 24px;
  color: #fff !important; // Override due to layout dependent colors
}

// Main positioning container
.download-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 90%;
  max-width: 720px;
  margin: 0 auto;
  background: #fff;

  &-inner {
    padding: $container-padding-mobile;
    @media screen and (min-width: 991px) {
      padding: $container-padding;
    }
  }
}

.download-link {
  cursor: pointer;
}

.formfield-container {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  color: $font-color;
  font-size: $font-size;
}

.formfield-wrapper-white {
  .formfield-input {
    background: rgba($benedict-blue, 0.03);
  }
  &-padding {
    padding: 0 !important;
  }
}

.formfield-wrapper-white,
.formfield-wrapper-grey {
  padding-top: $formfield-wrapper-padding;
  padding-bottom: $formfield-wrapper-padding;
  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
}

.formfield-wrapper-grey {
  background-color: #F9F9F9;
}

.formfield-wrapper-inner {
  max-width: 700px;
  margin: 0 auto;

  h2, h3 {
    color: #000;
    // Margin-top depends on parent (.formfield-wrapper-white, .formfield-wrapper-grey)
    // We clean the margin, so the number value is the true margin
    margin-top: 40px - $formfield-wrapper-padding;
    margin-bottom: 40px;
  }

  h2 {
    font-size: 24px;
  }

  &-outline {
    margin: 0 -50px;
    border: 1px solid #979797;
    padding: 30px 50px;
  }
}

.formfield-cols {
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
  }

  &-three {
    display: grid;
    grid-template-columns: 1fr;
    @media screen and (min-width: 768px) {
      grid-column-gap: 50px;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.formfield-cols-single {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-content: flex-start;
}

.formfield-wrap {
  margin-bottom: $wrap-margin;

  .download-container-inner & {
    margin-bottom: $wrap-margin-small;
  }

  width: 100%;

  @media screen and (min-width: 991px) {
    // Exception for downloads
    .download-container-inner & {
      width: 46%;

      &-full {
        width: 100%;
      }
    }
  }

  &-one-of-three {
    width: 30%;
  }

  &-two-of-three {
    width: 60%;
  }

  &-one-of-two {
    width: $half-width;
  }

  &-no-margin-bottom {
    margin-bottom: 0;
  }

  &-radio_selected_coursevariant {
    margin-bottom: -10px; // Negative margin
    .formfield-label {
      display: grid;
      align-content: center;
    }
  }

  // Exception for city radio
  &-radio-city {
    width: 70%;
  }

  // Exception for salutation radio
  &-radio-salutation {
    // Manually aligned with radio-city
    max-width: 230px;
  }

  // Exception for Gewünschte Wahlfächer
  &-checkbox_selected_courses {
    .formfield-label-checkbox-container {
      display: block;
    }
  }

  .formfield-wrap-with-input-right & {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 30px;
    align-items: center;
    margin-bottom: 10px;
  }
}

.formfield-wrap-full-download-agb {
  padding: 15px 0;
}

.formfield-wrap-with-input-right {
  width: 100%;

  &-headline {
    margin-bottom: 10px !important;
  }

  &-coltitle {
    text-transform: uppercase;
    color: $benedict-blue;
    font-weight: 300;
    text-align: center;
    margin-bottom: 0;
  }
}

.form-divider {
  border-top: 1px solid #C8C8C8;
  margin: 30px 0;
}

.formfield-label {
  display: block;
  margin-bottom: 4px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  min-height: 18px; // Height is set as we want to align columns properly

  &-checkbox,
  &-radio {
    // Checkbox label containing the checkbox and description
    display: flex;
    padding: 8px 0;
    font-weight: 400;
    color: $font-color;
    font-size: $font-size;

    // Wrapper for all checkbox/radio items
    &-container {
      margin-top: 10px;
      display: grid;

      &.one-of-two {
        grid-template-columns: 1fr 1fr;
      }

      &.one-of-three {
        grid-template-columns: 1fr 1fr 1fr;
      }

      &.half-and-two-of-three {
        grid-template-columns: 2fr 1fr 1fr;
      }
    }


    &-inner {
      // Container for Text beside checkbox
      margin-left: 10px;
    }

    a {
      color: $benedict-blue;
      text-decoration: underline;
    }
  }

  .formfield-wrap-with-input-right & {
    font-size: $font-size;
    font-weight: 400;
    text-transform: none;
  }
}

.formfield-isrequired {
  font-weight: 700;
  font-size: 120%; // Make the star a bit larger
  color: $benedict-blue;
  // Because of 'jumping elemets in forms with asterisk and without asterisk'
  line-height: 1;
}

.formfield-error {
  display: none;
  float: right;
  text-transform: none;
  font-size: 11px;
  font-weight: 400;
  color: $benedict-red;

  .formfield-label-checkbox & {
    // Position left for checkboxes
    // Add margin left to align with text
    float: none;
    margin-left: 25px;
  }
}

.formfield-input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #c8c8c8;
  padding: 10px;
  transition: border 500ms;
  font-size: $font-size;

  &:focus {
    outline: none;
    border: 1px solid #777;
  }

  &-error {
    border: 1px solid $benedict-red !important; // Override color change on focus
  }

  .formfield-wrap-with-input-right & {
    padding: 8px 10px;
  }
}

.formfield-textarea {
  @extend .formfield-input;
  height: 160px;
}

.formfield-select {
  width: 100%;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-padding-start: 10px;
  -webkit-padding-end: 30px;
  padding: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: url(/assets/img/project/icon/caret-down-solid.svg) right 10px center no-repeat #fff;
  background-size: 10px;
  border: 1px solid #ccc;
}

.formfield-description {
  font-size: 10px;
  color: $font-color;
}

.formfield-submit {
  padding: 18px 52px;
  border: none;
  background: $benedict-blue;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  transition: background-color 300ms;

  &:hover {
    background: $benedict-dark-blue;
  }
}

// Honeypot
.email20 {
  position: fixed;
  top: -1000px;
  left: -1000px;
}

.course-price {
  //color: $benedict-blue !important;
  font-family: inherit;
  margin-bottom: 20px !important;

  small {
    @extend .course-price;
  }

  &-headline {
    margin-bottom: 20px !important;
  }
}

.other-costs {
  &-header {
    font-size: $font-size;
    margin: 30px 0 18px 0;
  }

  &-wrapper {
    display: grid;
    grid-template-columns: auto 150px;
    grid-gap: 50px;
    font-size: 12px;
    border-top: 1px solid #C8C8C8;
    border-bottom: 1px solid #C8C8C8;
    padding: 7px 0 3px 0;
    margin-top: -1px; // Override double divider
  }

  &-description {
  }

  &-price {
    text-align: right;
  }

  &-additional-description {
    margin-top: 40px;
    font-size: 12px;
    color: $font-form-blue;
  }
}

.form-checkout-wrap {
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-column-gap: 50px;
    align-items: start;
    .formfield-checkbox-toc {
      margin-top: 0;
      padding-top: 0;
    }
  }
}



// Formfield progress bar
.formfield {
  &-progress-wrapper {
    @extend .quiz-progress-wrapper;
    z-index: 0;
    padding: 20px 0 !important;
    @media screen and (max-width: 992px) {
      margin-bottom: 20px;
    }
  }


  &-progress {
    @extend .quiz-progress;
  }

  &-progress-bar {
    @extend .quiz-progress-bar;
  }


  &-progress-info {
    @extend .quiz-progress-info;
  }

  &-progress-step {
    @extend .quiz-progress-number;
  }

}

.formfield-wrapper-rent-rooms .formfield-wrapper-white:not(:first-child) {
  display: none;
}
