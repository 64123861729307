@charset "UTF-8";

@font-face {
  font-family: 'benedict';
  src: url('../fonts/project/benedict.eot');
  src: url('../fonts/project/benedict.eot?#iefix') format('embedded-opentype'),
  url('../fonts/project/benedict.woff') format('woff'),
  url('../fonts/project/benedict.ttf') format('truetype'),
  url('../fonts/project/benedict.svg#benedict') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[data-icon]:before {
  font-family: "benedict" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "benedict" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-admin-user:before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url('../../assets/img/project/icon/bene-icon-1.svg') no-repeat center;
}

.icon-calendar:before {
  content: "\f073";
}

.icon-book-large:before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url('../../assets/img/project/icon/bene-icon-2.svg') no-repeat center;
}

.icon-bullet-icon1x:before {
  content: "\63";
}

.icon-bullet-icon-inactive1x:before {
  content: "\64";
}

.icon-icon-1:before {
  content: "\65";
}

.icon-icon-2:before {
  content: "\66";
}

.icon-icon-3:before {
  content: "\67";
}

.icon-icon-4:before {
  content: "\68";
}

.icon-icon-5:before {
  content: "\69";
}

.icon-icon-6:before {
  content: "\6a";
}

.icon-icon-7:before {
  content: "\6b";
}

.icon-icon-8:before {
  content: "\6c";
}

.icon-icon-9:before {
  content: "\6d";
}

.icon-icon-10 {
  &:before {
    display: inline-block;
    width: 66px;
    height: 66px;
    content: "";
    background: url('../../assets/img/project/icon/study-large.png') no-repeat center;
    background-size: cover;
  }
}

.icon-mail:before {
  content: "\6e";
}

.icon-menu-arrow-11x:before {
  content: "\6f";
}

.icon-menu-arrow-21x:before {
  content: "\70";
}

$left-padding-menu-icons: 10px;
.icon-it-lehre-4,
.icon-menu-icon-statistics1x,
.icon-menu-icon-hat1x,
.icon-menu-icon-presentation1x,
.icon-menu-icon-medizin1x,
.icon-menu-icon-mac1x,
.icon-menu-icon-bubble1x,
.icon-menu-icon-briefcase1x {
  padding-left: 52px !important;
  background-position: center left 5px !important;
  @media screen and (min-width: 991px) {
    display: inline-block !important;
    margin-left: initial;
    padding-left: 42px !important;
    background-position: center left 5px !important;
  }
}

.icon-menu-icon-briefcase1x {
  background: sassvg("ic-handel", #fff) no-repeat;
}

.icon-menu-icon-bubble1x {
  background: sassvg("ic-sprachschule", #fff) no-repeat;
}

.icon-menu-icon-mac1x {
  background: sassvg("ic-informatik-efz", #fff) no-repeat;
}

.icon-menu-icon-medizin1x {
  background: sassvg("ic-medizin", #fff) no-repeat;
}

.icon-menu-icon-pill1x:before {
  content: "\75";
}

.icon-menu-icon-presentation1x {
  background: sassvg("ic-kv-asubildung", #fff) no-repeat;
}

.icon-menu-icon-statistics1x {
  background: sassvg("ic-kader", #fff) no-repeat;
}

.icon-menu-icon-hat1x {
  background: sassvg("ic-ba-msc", #fff) no-repeat center;
}

.icon-it-lehre-4 {
  background: sassvg("ic-informatiker-efz", #fff) no-repeat;
}

.icon-mortarboard:before {
  content: "\78";
}

.icon-mouse-wires:before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url('../../assets/img/project/icon/bene-icon-5.svg') no-repeat center;
  background-size: 75%;
}

.icon-notes1x:before {
  content: "";
  width: 30px;
  height: 30px;
  display: inline-block;
  margin-right: 20px;
  background: url('../../assets/img/project/icon/bene-icon-2.svg') no-repeat center;
}

.icon-phone-handle1x:before {
  content: "";
  width: 30px;
  height: 30px;
  display: inline-block;
  margin-right: 20px;
  background: url('../../assets/img/project/icon/bene-icon-phone-dark.svg') no-repeat center;
}

.icon-printer:before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url('../../assets/img/project/icon/bene-icon-3.svg') no-repeat center;
  background-size: 80%;
}

.icon-table:before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url('../../assets/img/project/icon/ic-table.svg') no-repeat left 2px top 5px;
  background-size: 80%;
  margin-right: 10px;
  margin-top: -6px;
  transform: translateY(5px);
}

.icon-ringer:before {
  content: "";
  width: 30px;
  height: 30px;
  display: inline-block;
  margin-right: 20px;
  background: url('../../assets/img/project/icon/bene-icon-club.svg') no-repeat center;
}

.icon-search-icon1x {
  background: sassvg("ic-search", #fff) no-repeat center;
  min-width: 50px !important;
  background-size: 20px !important;
  @media (min-width: 1281px) {
    background: sassvg("ic-search", #000) no-repeat center;
  }
}

//.icon-socail-xing1x:before {
//  content: "\45";
//}
//.icon-social-facebook1x:before {
//  content: "\46";
//}
//.icon-social-linkedin1x:before {
//  content: "\47";
//}
.icon-tags-2.course-location-headline {
  &:before {
    content: "";
    width: 26px;
    height: 26px;
    display: inline-block;
    background: url('../../assets/img/project/icon/bene-icon-4-red.svg') no-repeat center;
  }
}

.icon-calendar.course-location-headline {
  &:before {
    content: "";
    width: 26px;
    height: 26px;
    display: inline-block;
    background: url('../../assets/img/project/icon/bene-calendar.svg') no-repeat center left;
    background-size: 90%;
  }
}

.icon-pin-map.course-location-headline {
  &:before {
    content: "";
    width: 26px;
    height: 26px;
    display: inline-block;
    background: url('../../assets/img/project/icon/bene-location.svg') no-repeat center;
  }
}

.icon-tags-2:before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url('../../assets/img/project/icon/bene-icon-4.svg') no-repeat center;
}

.icon-users1x:before {
  content: "";
  width: 30px;
  height: 30px;
  display: inline-block;
  margin-right: 20px;
  background: url('../../assets/img/project/icon/bene-icon-1.svg') no-repeat center;
}

.icon-it-lehre-1:before {
  content: "\4a";
}

.icon-it-lehre-2:before {
  content: "\4b";
}

.icon-it-lehre-3:before {
  content: "\4c";
}

.icon-child:before {
  content: "\4e";
}

.icon-map:before {
  content: "\4f";
}

.icon-parking:before {
  content: "\50";
}

.icon-pdf:before {
  content: "\51";
}

.icon-pin-map:before {
  content: "\52";
}

.icon-zu-fuss:before {
  content: "\53";
}

.icon-mit-den-ov:before {
  content: "\54";
}

.icon-mit-dem-fahrrad:before {
  content: "\55";
}

.icon-mit-dem-auto:before {
  content: "\56";
}

.icon-info-icon:before {
  content: "\57";
}

.icon-calendar:before {
  content: "\58";
}

.icon-video:before {
  content: "\59";
}

/*
.icon-phone-header:before { 
  content: "\5a";
}
*/


@font-face {
  font-family: 'EuclidCircularB-Bold-WebXL';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('EuclidCircularB-Bold-WebXL'), local('EuclidCircularB-Bold-WebXL'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-Bold-WebXL.woff2') format('woff2'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-Bold-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'EuclidCircularB-BoldItalic-WebXL';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('EuclidCircularB-BoldItalic-WebXL'), local('EuclidCircularB-BoldItalic-WebXL'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-BoldItalic-WebXL.woff2') format('woff2'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-BoldItalic-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'EuclidCircularB-Light-WebXL';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('EuclidCircularB-Light-WebXL'), local('EuclidCircularB-Light-WebXL'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-Light-WebXL.woff2') format('woff2'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-Light-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'EuclidCircularB-LightItalic-WebXL';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('EuclidCircularB-LightItalic-WebXL'), local('EuclidCircularB-LightItalic-WebXL'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-LightItalic-WebXL.woff2') format('woff2'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-LightItalic-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'EuclidCircularB-Medium-WebXL';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('EuclidCircularB-Medium-WebXL'), local('EuclidCircularB-Medium-WebXL'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-Medium-WebXL.woff2') format('woff2'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-Medium-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'EuclidCircularB-MediumItalic-WebXL';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('EuclidCircularB-MediumItalic-WebXL'), local('EuclidCircularB-MediumItalic-WebXL'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-MediumItalic-WebXL.woff2') format('woff2'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-MediumItalic-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'EuclidCircularB-Regular-WebXL';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('EuclidCircularB-Regular-WebXL'), local('EuclidCircularB-Regular-WebXL'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-Regular-WebXL.woff2') format('woff2'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-Regular-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'EuclidCircularB-RegularItalic-WebXL';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('EuclidCircularB-RegularItalic-WebXL'), local('EuclidCircularB-RegularItalic-WebXL'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-RegularItalic-WebXL.woff2') format('woff2'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-RegularItalic-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'EuclidCircularB-Semibold-WebXL';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('EuclidCircularB-Semibold-WebXL'), local('EuclidCircularB-Semibold-WebXL'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-Semibold-WebXL.woff2') format('woff2'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-Semibold-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'EuclidCircularB-SemiboldItalic-WebXL';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local('EuclidCircularB-SemiboldItalic-WebXL'), local('EuclidCircularB-SemiboldItalic-WebXL'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-SemiboldItalic-WebXL.woff2') format('woff2'),
  url('https://cdn.benedict.ch/fonts/EuclidCircularB/EuclidCircularB-SemiboldItalic-WebXL.woff') format('woff');
}